import axios from 'axios'
import Util from "@/js/Util.js"
import Home from "@/views/Home.vue";

const api = {
    getShop: "/api/getshop/",
    getShopWithId: "/api/getshopwithid/",
    getThumbnail: "/api/getthumb/"
}

function getShopWithConditions(conditions) {
    // 検索キーワードを成形する
    const params = Util.moldingConditionsForGetShop(conditions)
    const requestUrl = api.getShop + params
    
    Home.hotWord = ["名古屋", "焼き鳥"]

    axios.get(requestUrl).then(function (response) {
        console.log(response.data)
        Home.searchResult = "aaa"
        console.log("bbb")
    }).catch(function (response) {
        console.log(response)
    });
}

export default {
    getShopWithConditions, api
}