<template>
  <div class="search-shop-conditions">
    <div class="text-left" id="label-search-condition">
      <div v-on:click="handleLabelReset()">
        <i class="search icon black"></i><b>お探し中のメニュー </b
        ><a class="ui labeled icon"
          ><i class="trash alternate icon circular link black"></i
        ></a>
      </div>
      <div id="label-condition-area">
        <div
          class="ui label large basic black"
          v-for="value in searchWords"
          :key="value.id"
        >
          {{ value.word }}
          <i v-on:click="handleLabelClose(value.id)" class="close icon"></i>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    searchWords: Array
  },
  setup(props, { emit }) {
    // ボタンのイベントハンドラ
    const handleLabelClose = id => {
      emit("label-close", id);
    };
    const handleLabelReset = () => {
      emit("label-reset");
    };
    return { handleLabelClose, handleLabelReset };
  }
};
</script>

<style scoped>
.search-shop-conditions {
  max-width: 800px;
  margin-right: auto;
  margin-left: auto;
}

#label-condition-area {
  margin-bottom: 16px;
  margin-top: 8px;
  margin-left: 12px;
}

#label-condition-area div {
  margin-bottom: 8px;
  margin-right: 4px;
  margin-left: 4px;
}
</style>