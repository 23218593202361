<template>
  <div class="home">
    <div id="search-area">
      <SearchShopArea @label-add="addLabel" v-bind:hot-word="hotWord" />
      <SearchShopConditionArea
        v-bind:search-words="searchConditions"
        @label-close="deleteLabel"
        @label-reset="resetLabel"
      />
    </div>
    <CardShop
      :shops="searchResult"
      :loading="isPageLoading"
      :title="cardTitle"
    />
    <GoTop />
  </div>
</template>

<script>
import SearchShopArea from "@/components/SearchShopArea.vue";
import SearchShopConditionArea from "@/components/SearchShopConditionArea.vue";
import CardShop from "@/components/CardShop.vue";
import GoTop from "@/components/GoTop.vue";
import Mss from "@/js/ManageSessionStorage.js";
import axios from "axios";
import Util from "@/js/Util.js";
import Request from "@/js/Api.js";

export default {
  name: "Home",
  components: {
    SearchShopArea,
    SearchShopConditionArea,
    CardShop,
    GoTop
  },
  data: function() {
    return {
      searchConditions: [],
      searchResult: "",
      hotWord: ["居酒屋", "焼肉", "名古屋", "日本酒", "イタリアン", "ランチ"],
      cardTitle: "検索結果",
      pageNumber: 0,
      isPageLoading: false
    };
  },
  methods: {
    // 検索条件ラベルを削除
    deleteLabel: function(id) {
      const index = this.searchConditions.findIndex(v => v.id === id);
      this.searchConditions.splice(index, 1);
      this.getShop();
    },
    // 検索条件ラベルを追加
    addLabel: function(word) {
      var min = 0;
      var max = 10000000;
      var id = Math.floor(Math.random() * (max + 1 - min)) + min;
      this.searchConditions.push({ id: id, word: word });
      this.getShop();
    },
    // 検索条件をリセット（すべて削除）
    resetLabel: function() {
      this.searchConditions.splice(0);
      var te = { results: { results_available: 0, shops: [] } };
      this.searchResult = JSON.stringify(te);
    },

    // fordebug
    //TODO 将来的に別ファイルに移動したい...
    getShop: function() {
      console.log("called /api/getshop");
      this.getShopWithConditions(this.searchConditions);
    },

    getShopWithConditions: function(conditions) {
      if (!Object.keys(conditions).length) {
        return;
      }
      // 検索キーワードを成形
      const params = Util.moldingConditionsForGetShop(conditions);
      const requestUrl = Request.api.getShop + encodeURI(params);
      console.log("req to:", requestUrl);
      // リクエスト
      this.isPageLoading = true;
      axios.get(requestUrl).then(response => {
        // レスポンスをそのまま変数にセット
        this.isPageLoading = false;
        console.log(response.data);
        this.searchResult = JSON.stringify(response.data);
      });
    },

    getShopWithPageNum: function() {
      console.log("called getShopWithPageNum");
      const count = 20;
      let start = 0;
      var res = JSON.parse(this.searchResult);

      if (!(res.results.results_start === undefined)) {
        start = res.results.results_start;
      }
      if (res.results.results_available <= start) {
        // console.log("load caneceled");
        return;
      }
      const startAt = count + res.results.results_start;

      this.pageNumber += 1;
      // console.log("Next page..." + this.pageNumber);

      // 検索キーワードを成形
      const params = Util.moldingConditionsForGetShop(this.searchConditions);
      const requestUrl =
        Request.api.getShop + encodeURI(params) + "&start=" + startAt;
      console.log(requestUrl);

      // リクエスト
      this.isPageLoading = true;
      axios.get(requestUrl).then(response => {
        // レスポンスをそのまま変数にセット
        const conatedRes = Util.concatJsonForGetShopResponse(
          JSON.parse(this.searchResult),
          response.data
        );
        this.isPageLoading = false;
        this.searchResult = JSON.stringify(conatedRes);
      });
    },

    pager() {
      if (
        $(document).innerHeight() - $(window).innerHeight() <=
        $(window).scrollTop()
      ) {
        if (1 <= this.searchConditions.length && !this.isPageLoading) {
          console.log("Loading page!");
          this.getShopWithPageNum();
        }
      }
    }
  },
  created: function() {
    if (Mss.isStoredConditions()) {
      // 検索条件が一時保存されていれば取得
      this.searchConditions = Mss.getSearchConditionsObject();
    }
  },
  beforeRouteLeave(to, from, next) {
    // 別ページに遷移するときに呼ばれる
    // 検索条件を一時保存する
    Mss.storeSearchConditions(this.searchConditions);
    next();
  },
  mounted: function() {
    window.addEventListener("scroll", this.pager);
    this.getShop();
  },
  unmounted: function() {
    console.log("unmounted");
    window.removeEventListener("scroll", this.pager);
  }
};
</script>

<style>
.text-left {
  text-align: left;
}

.cursor-clickable {
  cursor: pointer;
}

/* #search-area {
  margin-right: 8px;
  margin-left: 8px;
} */

body {
  background-color: #f4f5f7;
}

.home {
  padding-top: 71px;
  /* background-image: url("../assets/ogasuta458A7880_TP_V.jpg");
  background-size: auto auto;
  background-attachment: fixed;
  background-repeat: no-repeat; */
}
</style>
