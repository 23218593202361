<template>
  <div class="nav">
    <GlobalHeader />
  </div>
  <div id="main">
    <router-view />
  </div>
</template>

<script>
import GlobalHeader from "@/components/GlobalHeader.vue";

export default {
  components: {
    GlobalHeader
  }
};
</script>
<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

body {
  touch-action: manipulation;
}

</style>
