
const storeKeySearchWord = "search-conditions"

// 検索条件をSessionStrageに保存
function storeSearchConditions (word) {
    sessionStorage.setItem( storeKeySearchWord, JSON.stringify(word));
}

// 検索条件をSessionStrageから取得(JSON形式)
function getSearchConditions () {
    return sessionStorage.getItem(storeKeySearchWord);
}

// 検索条件をSessionStrageから取得(オブジェクト形式)
// 通常利用はこちら
function getSearchConditionsObject () {
    return JSON.parse(sessionStorage.getItem(storeKeySearchWord));
}

// 検索条件が保存されているかを返却(true:保存済み, false:未保存)
function isStoredConditions () {
    var storedVal = sessionStorage.getItem(storeKeySearchWord);
    if (storedVal == null) {
        return false
    }
    return true
}

export default {
    storeSearchConditions, 
    getSearchConditions, 
    getSearchConditionsObject,
    isStoredConditions
}