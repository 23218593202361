import '../semantic/semantic.min.js'
import '../semantic/semantic.min.css'
import { createApp } from "vue";
import App from "./App";
import router from "./router";

// import '../flickity/flickity.pkgd.min.js'
// import '../flickity/flickity.min.css'

const app = createApp(App)
app.use(router)
app.mount("#app")