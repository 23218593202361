<template>
  <!-- サイドバーの中身 -->
  <div id="ui-sidebar" class="ui left sidebar vertical menu">
    <div class="item" id="menu-1">
      <img src="../assets/menu.png" />
    </div>

    <router-link to="/" class="item">
      <p><i class="icon search"></i> Search</p>
    </router-link>

    <router-link to="favorite" class="item">
      <p><i class="icon lemon green" /> Likes</p>
    </router-link>

    <router-link to="portfolio" class="item">
      <p><i class="icon list"></i> Lists</p>
    </router-link>

    <router-link to="signin" class="item">
      <p>
        <i class="icon sign-in"><i class="fas fa-salad"/></i> Sign in
      </p>
    </router-link>

    <router-link to="howtouse" class="item">
      <p><i class="icon question circle outline" /> Help</p>
    </router-link>
  </div>

  <!-- サイドバー ヘッダー部分 -->
  <div class="ui fixed menu">
    <div class="left item">
      <div v-on:click="toggleMenu">
        <i class="icon link book large"></i>
      </div>
    </div>
    <div>
      <router-link to="/">
        <!-- <img id="logo" src="@/assets/kokodo.png" /> -->
        <p id="title-nav">KoKodo</p>
      </router-link>
    </div>
    <div class="right menu">
      <div class=" item">
        <a href="#">
          <i class="icon twitter square black large link"></i>
        </a>
      </div>
      <div class="right item">
        <a href="#">
          <i class="icon instagram black large link"></i>
        </a>
      </div>
    </div>
  </div>
</template>


<script>
export default {
  methods: {
    toggleMenu: function() {
      $("#ui-sidebar")
        .sidebar("setting", {
          dimPage: true,
          transition: "overlay",
          mobileTransition: "overlay"
        })
        .sidebar("toggle");
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
#menu-1 {
  background-image: url("../assets/menu.png");
  width: 100%;
}
#ui-sidebar,
#title-nav {
  font-family: "Pacifico", cursive;
}

#title-nav {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

#ui-sidebar {
  background-image: url("../assets/f109.png");
  background-size: auto auto;
  font-size: 1.2em;
  background-color: #f4f5f7;
}
</style>
