import Util from "@/js/Util.js"

const key = {
    likes: "likes",
    list: "list",
}

const localStorageTemplate = {
    shopId: "default",
    addedAt: "19000101",
}

// LocalStrageに保存
function store(key, word) {
    localStorage.setItem(key, JSON.stringify(word));
}

// LocalStrageに保存から取得(JSON形式)
function getValuesJson(key) {
    return localStorage.getItem(key);
}

// LocalStrageに保存から取得(オブジェクト形式)
// 通常利用はこちら
function getValuesObject(key) {
    return JSON.parse(localStorage.getItem(key));
}

// 任意の値が保存されているかを返却(true:保存済み, false:未保存)
function isStored(key) {
    var storedVal = localStorage.getItem(key);
    if (storedVal == null) {
        return false
    }
    return true
}

function isThisIdHasAdded(id) {
    var ids = getLikedShopId()
    if (ids.indexOf(id) != -1) {
        return true
    }else {
        return false
    }
}

function addToLike(id) {
    const useKey = key.likes
    var storedValue = []
    if (isStored(useKey)) {
        storedValue = getValuesObject(useKey)
    }
    var setValue = localStorageTemplate;
    setValue.shopId = id;
    setValue.addedAt = Util.getYYYYMMDD();
    storedValue.push(setValue)
    store(useKey, storedValue)
}

function addToList(id) {
    const useKey = key.list
    var storedValue = []
    if (isStored(useKey)) {
        storedValue = getValuesObject(useKey)
    }
    var setValue = localStorageTemplate;
    setValue.shodId = id;
    setValue.addedAt = Util.getYYYYMMDD();
    storedValue.push(setValue)
    store(useKey, storedValue)
}

// Should be call isThisIdHasAdded() brefore this method calling.
function removeFromLike(id) {
    console.log("remove:" + id)
    var useKey = key.likes
    var shopId = getLikedShopId()
    var shops = getValuesObject(useKey)
    console.log("before:" + shops)
    shops.splice(shopId.indexOf(id), 1)
    console.log("after:" + shops)
    store(useKey, shops)
}


function getLikedShopId() {
    var shopId = []
    const useKey = key.likes
    if (isStored(useKey)) {
        const shops = getValuesObject(useKey)
        shops.forEach(shop => shopId.push(shop.shopId));
    }
    return shopId
}


export default {
    store,
    getValuesJson,
    getValuesObject,
    isStored,
    addToLike,
    addToList,
    getLikedShopId,
    isThisIdHasAdded,
    removeFromLike,
    key
}