<template>
  <div class="cardshop">
    <div class="cardshop-inner text-left">
      <div class="ui segments">
        <div class="ui segment">
          <b>{{ title }}：{{ shopDataRaw.results.results_available }}件 </b>
        </div>
        <div class="ui segment show-shop-area">
          <div class="ui cards raised two stackable">
            <div
              class="card"
              :id="'card-' + shop.id"
              v-for="(shop, index) in shopDataRaw.results.shop"
              :key="index"
            >
              <!-- <div class="content">
                <div class="ui labeled icon top attached">
                  {{ shop.genre.catch }}
                </div>
              </div> -->
              <div class="content">
                <div class="header">
                  <a
                    :href="shop.urls.pc"
                    rel="noopener noreferrer"
                    class="card-shop-name"
                    target="_blank"
                  >
                    {{ shop.name }}
                  </a>
                  <div class="ui right corner label">
                    <img src="../assets/pin_brown.png" />
                  </div>
                </div>
                <div class="meta">
                  {{ shop.catch }}
                </div>
                <div class="ui divider"></div>

                <div class="content">
                  <div class="swiper-container">
                    <div class="swiper-wrapper">
                      <div class="swiper-slide">
                        <a
                          :href="shop.urls.pc"
                          rel="noopener noreferrer"
                          target="_blank"
                        >
                          <img
                            class="ui image small rounded"
                            :src="shop.photo.pc.l"
                          />
                        </a>
                      </div>

                      <div
                        v-for="(thumb, index) in getShopThumbs(shop.id)"
                        :key="shop.id + '-thumb-' + index"
                        class="swiper-slide"
                      >
                        <a
                          :href="shop.urls.pc"
                          rel="noopener noreferrer"
                          target="_blank"
                        >
                          <img class="ui image small rounded" :src="thumb" />
                        </a>
                      </div>
                    </div>
                  </div>
                  <div
                    class="ui button basic compact fluid load-more-images"
                    :id="'card-button-load-image-' + shop.id"
                    v-on:click="
                      getNewThumbs(shop.id, shop.name, shop.station_name)
                    "
                  >
                    ほかの写真をみる
                  </div>
                </div>

                <div class="ui divider"></div>

                <div class="description">
                  <div class="ui two column grid">
                    <div class="column">
                      <div class="item">
                        <i class="icon yen sign"></i> {{ shop.budget.name }}
                      </div>
                      <div class="item">
                        <i class="icon sun"></i> ランチ {{ shop.lunch }}
                      </div>
                      <div class="item">
                        <i class="icon train"></i> {{ shop.station_name }}駅
                      </div>
                    </div>

                    <div class="column">
                      <div class="item">
                        <i class="icon users"></i> ～{{ shop.party_capacity }}名
                      </div>
                      <div class="item">
                        <i class="fas fa-smoking-ban"></i>
                        {{ shop.non_smoking }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="extra content">
                <div class="item">
                  <i
                    :class="getIconLike(shop.id) + ' link large'"
                    :id="'card-icon-' + shop.id"
                    v-on:click="toggleLike(shop.id)"
                  ></i>
                  <div class="right floated content">
                    <i class="icons link" v-on:click="addToList(shop.id)">
                      <i class="list black icon large"></i>
                      <i class="top right corner black add icon large"></i>
                    </i>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div id="loading-icon" v-show="isPageLoading">
      <i class="circular inverted utensils big loading icon orange"></i>
    </div>
  </div>
</template>

<script>
import mgls from "@/js/ManageLocalStorage.js";
import Request from "@/js/Api.js";
import axios from "axios";

export default {
  name: "CardShop",
  props: {
    shops: {
      type: String,
      default: "default"
    },
    title: {
      type: String,
      required: true
    },
    loading: {
      type: Boolean,
      required: true
    }
  },
  data: function() {
    return {
      shopDataRaw: {
        type: Object,
        results: { results_available: 0, shops: [] }
      },
      likedShop: [],
      isPageLoading: false,
      shopThumbs: {
        type: Object,
        shopIdHere: [{ src: "" }]
      }
    };
  },
  methods: {
    addToList: function(id) {
      // console.log("addToList:" + id);
      mgls.addToList(mgls.key.likes, id);
    },

    toggleLike: function(id) {
      if (!mgls.isThisIdHasAdded(id)) {
        $("#card-icon-" + id).removeClass("outline");
        mgls.addToLike(id);
      } else {
        $("#card-icon-" + id).addClass("outline");
        mgls.removeFromLike(id);
        this.likedShop = mgls.getLikedShopId();
      }
    },

    getIconLike: function(shopId) {
      var likes = this.likedShop;
      if (likes.indexOf(shopId) != -1) {
        return "icon lemon green";
      } else {
        return "icon lemon green outline";
      }
    },

    getShopThumbs: function(shopId) {
      var thumbs = [];
      var storedThumb = this.shopThumbs;
      if (!(Object.keys(this.shopThumbs).indexOf(shopId) === -1)) {
        thumbs = storedThumb[shopId];
      }
      return thumbs;
    },

    getNewThumbs: function(id, name, station) {
      const url =
        "/api/getthumb/" +
        encodeURI("?keyword=" + name + "&station=" + station);
      this.toggleLoaingIcon("card-button-load-image-" + id, true);
      axios.get(url).then(response => {
        this.toggleLoaingIcon("card-button-load-image-" + id, false);
        // 成功時の動作here
        Object.assign(this.shopThumbs, { [id]: response.data.result.img });
        this.initSwiper("card-" + id);
      });
    },
    toggleLoaingIcon: function(elId, addLoading) {
      console.log("#" + elId);
      if (addLoading) {
        $("#" + elId).addClass("loading");
      } else {
        $("#" + elId).addClass("disabled");
        $("#" + elId).removeClass("loading");
      }
    },
    initSwiper: function(targetCardId) {
      console.log("init swiper");
      console.log("target: #" + targetCardId + " .swiper-container");

      // eslint-disable-next-line no-undef
      new Swiper("#" + targetCardId + " .swiper-container", {
        slidesPerView: 2.3,
        spaceBetween: 16,
        loop: false,
        observer: true,
        freeMode: true,
        freeModeMomentum: true,
        freeModeSticky: true
      });
    }
  },
  created: function() {
    console.log("created");
    this.likedShop = mgls.getLikedShopId();
  },
  mounted: function() {
    console.log("mounted");
  },
  unmounted: function() {
    console.log("unmounted");
  },
  updated: function() {
    console.log("updated");
  },
  beforeUnmount: function() {
    console.log("beforeUnmount");
  },

  watch: {
    // eslint-disable-next-line no-unused-vars
    shops: function(newVal, oldVal) {
      this.shopDataRaw = JSON.parse(newVal);
    },
    loading: function(newVal, oldVal) {
      console.log("page loading", newVal, oldVal);
      this.isPageLoading = newVal;
    }
  }
};
</script>

<style scoped>
.cardshop-inner {
  max-width: 800px;
  margin-right: auto;
  margin-left: auto;
}

.show-shop-area {
  margin-right: auto;
  margin-left: auto;
}

.cards {
  margin-left: auto;
  margin-right: auto;
}

.card {
  background-color: #f4f5f7;
}

#loading-icon {
  position: fixed;
  left: 47.5%;
  bottom: 5%;
}

#loading-icon a {
  position: relative;
  display: block;
}

.load-more-images {
  margin-top: 16px;
}

.show-shop-area {
  background-image: url("../assets/cork.jpg");
  /* background-color: #FFFFFF00; */
}
</style>
