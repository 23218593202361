<template>
  <div class="search-shop-area">
    <div class="search-shop-area-inner search-shop-area-box">
      <!-- <h1 class="ui header text-left">
        KoKodo
        <div class="sub header">
          迷うならココで決めよう。<br>
          飲食店の共有と検索はココドゥ。
        </div>
      </h1>
      <div class="ui divider"></div> -->

      <div class="ui search search-shop-area-box">
        <div class="text-left search-shop-area-box" id="search-input-example">
          <div id="message-chef">
            <img class="icon-svg" src="../assets/icon-chef.svg" />
            本日はなにをお探しでしょうか？
          </div>

          <div id="label-hot-area">
            <a
              class="ui label large basic black"
              v-on:click="addLabel(value)"
              v-for="(value, index) in hotWord"
              :key="index"
              >{{ value }}</a
            >
          </div>
        </div>
        <div class="ui icon input" id="seach-box">
          <input
            id="input-search-word"
            @keydown.enter="addLabelFromInput()"
            name="tags"
            class="prompt"
            type="text"
            placeholder="新橋駅　居酒屋　焼肉"
          />
          <i class="search icon"></i>
        </div>
      </div>
      <div class="ui divider"></div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    hotWord: {
      type: Array,
      default: ["東京", "焼肉"]
    }
  },
  setup(props, { emit }) {
    // ボタンのイベントハンドラ
    const addLabel = word => {
      emit("label-add", word);
    };

    const addLabelFromInput = () => {
      const word = $("#input-search-word").val();
      if (!word) {
        return;
      }
      // 空文字以外は追加する
      var tagetString = word;
      // 空白の削除処理
      tagetString = tagetString.replace(/^\s+|\s+$/g, "");
      var arrayStrig = tagetString.split(/[\x20\u3000]/);
      arrayStrig = arrayStrig.filter(entry => entry.trim() != "");
      arrayStrig.forEach(function(val, index) {
        // 配列の値ごとにadd
        emit("label-add", val);
      });
      // 値の初期化
      $("#input-search-word").val("");
    };
    return { addLabel, addLabelFromInput };
  },
  mounted: function() {
    $("ui.accordion").accordion();
  }
};
</script>
<style scoped>
/* .search-shop-area {
  background-color: beige;
} */
.search-shop-area-inner {
  max-width: 800px;
  margin-right: auto;
  margin-left: auto;
  /* background-color: darkgray; */
}

.search-shop-area-box {
  margin-top: 8px;
  margin-bottom: 8px;
}

#seach-box {
  width: 100%;
  margin-bottom: 8px;
}

#search-input-example {
  margin-bottom: 8px;
}

#label-hot-area {
  margin-bottom: 16px;
  margin-top: 8px;
  margin-left: 12px;
}

#label-hot-area a {
  margin-bottom: 8px;
  margin-right: 4px;
  margin-left: 4px;
}

.icon-svg {
  width: auto;
  height: 30px;
}

#message-chef {
  vertical-align: middle;
}
</style>