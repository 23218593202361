<template>
  <div id="page_top" v-on:click="hashMove">
    <i
      class="icon circular inverted orange counterclockwise rotated link large"
    >
      <i class="fas fa-fish"></i>
    </i>
  </div>
</template>

<script>
export default {
  name: "GoTop",
  methods: {
    // eslint-disable-next-line no-unused-vars
    hashMove: function(trg) {
      $("body,html").animate({ scrollTop: 0 }, 750, "swing");
    }
  }
};
</script>

<style scoped>
#page_top {
  position: fixed;
  right: 3%;
  bottom: 3%;
}
#page_top a {
  position: relative;
  display: block;
}
</style>