// API側に送るパラメータ
const param = { keyword: "?keyword=" };

function moldingConditionsForGetShop(conditions) {
    // 検索キーワードは半角スペース区切りとする
    var url = param.keyword
    for (let key in conditions) {
        url += conditions[key].word
        url += " "
    }
    return url.trim()
}

function getYYYYMMDD() {
    var now = new Date();
    return now.getFullYear() + ("0" + (now.getMonth() + 1)).slice(-2) + ("0" + now.getDate()).slice(-2);
}

function concatJsonForGetShopResponse(oldVal, resVal) {
    var oldShops = oldVal.results.shop;
    var resShops = resVal.results.shop;
    const newShops = oldShops.concat(resShops);
    console.log("called concatJsonForGetShopResponse")
    // console.log("oldVal:" + JSON.stringify(oldVal))
    console.log("-------------------------------------------------------------------")
    // console.log("newVal:" + JSON.stringify(newVal))
    resVal.results.shop = newShops;
    console.log(resVal)
    return resVal;
    
}

export default {
    moldingConditionsForGetShop, getYYYYMMDD, concatJsonForGetShopResponse
}